import { CommonModule } from '@angular/common';
import {
  AfterContentInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { NgSelectComponent } from '@ng-select/ng-select';
import { select, Store } from '@ngrx/store';
import { format } from 'date-fns/format';
import { getUnixTime } from 'date-fns/getUnixTime';
import { parse } from 'date-fns/parse';
import datepicker, { Datepicker } from 'js-datepicker';
import { firstValueFrom, Subscription } from 'rxjs';

import { ButtonComponent } from '../../../../../components/button/button.component';
import { IconComponent } from '../../../../../components/icon/icon.component';
import { InputComponent } from '../../../../../components/input/input.component';
import { LoadingComponent } from '../../../../../components/loading/loading.component';
import { ModalLayoutComponent } from '../../../../../components/modal-layout/modal-layout.component';
import { SiteAddressComponent } from '../../../../../components/site-address/site-address.component';
import { Address } from '../../../../../interfaces/address.interface';
import { JobActions } from '../../../../../store/actions/job.actions';
import { OrganisationActions } from '../../../../../store/actions/organisation.actions';
import { selectJobState } from '../../../../../store/selectors/job.selector';
import { selectAllOrganisationUsers } from '../../../../../store/selectors/organisation.selector';
import {
  selectActiveOrganisationUserRole,
  selectUser,
} from '../../../../../store/selectors/user.selector';
import { formatAddress } from '../../../../../utils/address';

@Component({
  selector: 'app-create-job-modal',
  standalone: true,
  templateUrl: './create-job.component.html',
  imports: [
    LoadingComponent,
    FormsModule,
    ReactiveFormsModule,
    SiteAddressComponent,
    CommonModule,
    IconComponent,
    InputComponent,
    ButtonComponent,
    ModalLayoutComponent,
    NgSelectComponent,
  ],
})
export class CreateJobModalComponent
  implements OnInit, OnDestroy, AfterContentInit
{
  @ViewChild('dateElement')
  private readonly dateElement!: ElementRef<HTMLDivElement>;

  private datePicker: Datepicker | undefined = undefined;

  // allows the form control to initialise with null and be patched with a string
  private assignToValue: string | null = null;

  public _show = false;

  public get show(): boolean {
    return this._show;
  }

  @Input()
  public set show(value: boolean) {
    this._show = value;

    if (this._show) {
      this.resetState();
      this.setupDatepicker();
    } else {
      this.destroyDatepicker();
    }
  }

  @Output()
  public cancelled: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  public closed: EventEmitter<void> = new EventEmitter<void>();

  public view: 'initial' | 'response' = 'initial';

  public userRole$ = this.store.pipe(select(selectActiveOrganisationUserRole));
  public organisationUsersSub!: Subscription;

  public organisationUsers: { id: string; name: string }[] = [];
  public organisationUsers$ = this.store.pipe(
    select(selectAllOrganisationUsers),
  );
  public loading = false;
  public error: unknown = null;

  public readonly form = new FormGroup({
    date: new FormControl('', {
      validators: [Validators.required],
      nonNullable: true,
    }),
    clientReference: new FormControl('', Validators.required),
    customerName: new FormControl('', [Validators.required]),
    siteAddress: new FormControl('', [Validators.required]),
    assignTo: new FormControl(this.assignToValue, [Validators.required]),
  });

  private readonly jobSub = this.store
    .pipe(select(selectJobState))
    .subscribe(({ error, pending }) => {
      this.error = error;
      this.loading = pending;
    });

  public constructor(
    private readonly store: Store,
    private readonly changeDetector: ChangeDetectorRef,
  ) {}

  public async ngOnInit(): Promise<void> {
    const userRole = await firstValueFrom(this.userRole$);

    if (userRole !== 'USER') {
      this.organisationUsersSub = this.organisationUsers$.subscribe(
        (params) => {
          this.organisationUsers = [];
          this.organisationUsers = params
            .filter(({ status }) => status === 'ACTIVE')
            .map(({ userId, profile }) => ({
              id: userId,
              name: `${profile.firstName} ${profile.lastName}`,
            }));
        },
      );
    } else {
      const { user } = await firstValueFrom(this.store.select(selectUser));
      this.form.patchValue({ assignTo: user?.id });
    }
  }

  public ngOnDestroy(): void {
    this.jobSub?.unsubscribe();
    this.organisationUsersSub?.unsubscribe();
  }

  public ngAfterContentInit(): void {
    this.changeDetector.detectChanges();
  }

  public selectSiteAddress({
    line1,
    line2,
    line3,
    city,
    county,
    postalCode,
  }: Address): void {
    this.form.patchValue({
      siteAddress: formatAddress(line1, line2, line3, city, county, postalCode),
    });
  }

  public async createJob(): Promise<void> {
    const { user } = await firstValueFrom(this.store.pipe(select(selectUser)));

    if (!user) {
      this.cancel();
      return;
    }

    if (!this.form.valid || !user) {
      return;
    }

    const { siteAddress, date, clientReference, customerName, assignTo } =
      this.form.value;

    if (
      !assignTo ||
      !siteAddress ||
      !date ||
      !clientReference ||
      !customerName
    ) {
      return;
    }

    this.store.dispatch(
      JobActions.create({
        payload: {
          id: `${user.activeOrganisation.organisation.id}${format(new Date(), 'ddMMkkmm')}`,
          site: siteAddress,
          userId: assignTo,
          clientReference,
          customerName,
          date: getUnixTime(parse(date, 'dd/MM/yyyy', new Date())),
        },
      }),
    );

    this.view = 'response';
  }

  public cancel(): void {
    this.resetState();
    this.cancelled.emit();
  }

  public close(): void {
    this.resetState();
    this.closed.emit();
  }

  private async resetState(): Promise<void> {
    const userRole = await firstValueFrom(this.userRole$);

    if (userRole !== 'USER') {
      this.store.dispatch(OrganisationActions.getAllUsers());
    }

    this.form.reset();
    this.view = 'initial';
    this.loading = false;
    this.error = null;
  }

  private setupDatepicker(): void {
    this.changeDetector.detectChanges();

    if (this.dateElement && !this.datePicker) {
      this.datePicker = datepicker(this.dateElement.nativeElement, {
        onSelect: (_instance, date) =>
          this.form.patchValue({
            date: date ? format(date, 'dd/MM/yyyy') : '',
          }),
      });
    }
  }

  private destroyDatepicker(): void {
    this.changeDetector.detectChanges();

    if (this.dateElement && this.datePicker) {
      this.datePicker.remove();
      this.datePicker = undefined;
    }
  }
}
