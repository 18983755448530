<app-modal-layout (clickedOutside)="cancel()" [hidden]="!show">
  <div
    class="h-full w-full rounded-none bg-white p-14 md:h-auto md:w-auto md:rounded-2xl"
  >
    <ng-container *ngIf="view === 'initial'; else responseView">
      <h2 i18n class="mb-2 text-2xl font-medium">Create a job</h2>

      <form
        class="mt-12 grid grid-cols-1 gap-x-2 md:grid-cols-2 md:gap-x-4"
        [formGroup]="form"
        (submit)="$event.preventDefault()"
      >
        <app-input
          [form]="form"
          controlName="customerName"
          i18n-label
          label="Customer name"
          i18n-placeholder
          placeholder="Customer name"
          name="customerName"
        />

        <div class="mb-4">
          <h2 i18n class="mb-2 text-sm font-light text-gray-600">Date</h2>

          <div
            #dateElement
            class="flex h-[50px] w-full items-center justify-between rounded border border-alpha-gray-40 p-2"
          >
            <p
              class="pointer-events-none"
              [class.text-alpha-gray-60]="!form.value.date"
            >
              {{ form.value.date || "DD/MM/YYYY" }}
            </p>
            <app-icon icon="calendar" class="pointer-events-none h-auto w-4" />
          </div>
        </div>

        <app-input
          [form]="form"
          controlName="clientReference"
          i18n-label
          label="Your reference"
          i18n-placeholder
          placeholder="Your reference"
          name="clientReference"
        />

        <div *ngIf="(userRole$ | async) !== 'USER'" class="mb-4 flex flex-col">
          <h2 i18n class="mb-2 text-sm font-light text-gray-600">Assign to</h2>
          <ng-select
            [items]="organisationUsers"
            formControlName="assignTo"
            bindLabel="name"
            [searchable]="false"
            bindValue="id"
            [clearable]="false"
            i18n-placeholder
            placeholder="Please select"
          >
          </ng-select>
        </div>

        <app-site-address (selected)="selectSiteAddress($event)" />

        <div class="w-40 md:w-80">
          <ng-content *ngIf="form.value.siteAddress">
            <p i18n class="mb-2 text-sm font-light text-gray-600">
              Site Address
            </p>

            <div class="flex">
              <app-icon height="24px" icon="location" />
              <p class="ml-2">
                {{ form.value.siteAddress }}
              </p>
            </div>
          </ng-content>
        </div>
      </form>
      <div class="mt-8 flex flex-col md:flex-row md:justify-end">
        <app-button
          classes="w-full md:w-auto px-4"
          type="button"
          i18n-text
          (click)="cancel()"
          text="Cancel"
        />

        <app-button
          type="submit"
          (click)="createJob()"
          [disabled]="loading"
          i18n-text
          icon="add"
          iconFill="white"
          iconHeight="14px"
          text="Create job"
          classes="w-full md:w-auto px-4 md:ml-4 mt-4 md:mt-0"
        />
      </div>
    </ng-container>

    <ng-template #responseView>
      <div class="flex h-full w-full flex-col items-center justify-center">
        <ng-container *ngIf="loading">
          <app-loading></app-loading>
        </ng-container>

        <ng-container *ngIf="!loading">
          <ng-container *ngIf="error; else responseSuccess">
            <div
              class="mx-auto flex h-40 w-40 items-center justify-center rounded-full bg-alpha-green"
            >
              <div class="h-16">
                <app-icon icon="warning" />
              </div>
            </div>

            <div class="mt-6 text-center">
              <h2 i18n class="mb-2 text-2xl font-medium">An error occurred</h2>
              <p i18n class="font-light">
                Unable to create job, please try again.
              </p>
            </div>

            <div class="mt-8 flex w-full flex-col">
              <app-button
                (click)="createJob()"
                type="submit"
                i18n-text
                text="Try again"
                classes="w-full"
              />
              <app-button
                i18n-text
                text="Cancel"
                (click)="cancel()"
                classes="w-full mt-4"
              />
            </div>
          </ng-container>

          <ng-template #responseSuccess>
            <div
              class="mx-auto flex h-40 w-40 items-center justify-center rounded-full bg-alpha-green"
            >
              <div class="h-16">
                <app-icon icon="success" />
              </div>
            </div>

            <div class="mt-6 text-center">
              <h2 i18n class="mb-2 text-2xl font-medium">Job created!</h2>
              <p i18n class="font-light">Job created successfully.</p>
            </div>

            <div class="mt-8 flex flex-col">
              <button (click)="close()" i18n class="text-alpha-blue">
                Back to dashboard
              </button>
            </div>
          </ng-template>
        </ng-container>
      </div>
    </ng-template>
  </div>
</app-modal-layout>
