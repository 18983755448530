<div class="flex flex-col md:flex-row md:justify-between">
  <h1 class="text-3xl font-medium" i18n>Jobs &amp; tests overview</h1>
  <app-button text="Create a job" (click)="createJobModalOpen = true"
    classes="w-full md:w-auto px-3 flex justify-center md:justify-start mt-2 md:mt-0" iconFill="white" iconHeight="14px"
    icon="add" type="submit" />
</div>

<app-segments activeValue="details" [items]="[
    {
      icon: 'details',
      value: 'details',
      title: 'Jobs'
    },
  ]" />

<div class="my-4">
  <app-search (search)="search($event)" />
</div>

<div *ngIf="!createJobModalOpen"
  class="mt-10 grid w-full grid-cols-1 gap-4 pb-8 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 3xl:grid-cols-5">
  <div class="relative w-full rounded-xl p-6 shadow" *ngFor="let job of jobs$ | async">
    <div class="absolute right-4 top-4">
      <app-button icon="ellipsis" iconFill="#1990FF" classes="h-10 w-10" (click)="jobMenuOpen = job.id" />
    </div>

    <div>
      <h2 i18n class="text-sm font-light text-gray-600">Order number</h2>

      <p class="mb-5 mt-1 truncate pr-4 text-xl text-black">{{ job.id }}</p>
    </div>

    <div>
      <h2 i18n class="text-sm font-light text-gray-600">Customer reference</h2>

      <p class="mb-5 mt-1 text-xl text-black">{{ job.clientReference }}</p>
    </div>

    <div>
      <h2 i18n class="text-sm font-light text-gray-600">Site address</h2>

      <p class="mb-5 mt-1 text-xl text-black">{{ job.site }}</p>
    </div>

    <div>
      <h2 i18n class="text-sm font-light text-gray-600">Status</h2>

      <p class="mb-5 mt-1 text-xl text-black">{{ job.status | titlecase }}</p>
    </div>

    <app-menu [show]="jobMenuOpen === job.id" (closed)="jobMenuOpen = ''">
      <app-menu-action i18n-text text="View job details" (click)="viewJob(job.id)" />
    </app-menu>
  </div>
</div>

<div *ngIf="(isPending$ | async) === false && !(jobs$ | async)?.length" class="mt-20 flex items-center justify-center">
  <h1 i18n class="text-3xl text-alpha-gray-80">
    No results, please try another search.
  </h1>
</div>

<div *ngIf="(isPending$ | async)" class="mt-20 flex items-center justify-center">
  <app-loading />
</div>

<app-create-job-modal [show]="createJobModalOpen" (cancelled)="createJobModalOpen = false"
  (closed)="createJobModalClosed()" />