import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { firstValueFrom } from 'rxjs';

import { ButtonComponent } from '../../../components/button/button.component';
import { LoadingComponent } from '../../../components/loading/loading.component';
import { SegmentsComponent } from '../../../components/segments/segments.component';
import { JobSearchParams } from '../../../interfaces/job.interface';
import { JobActions } from '../../../store/actions/job.actions';
import {
  selectJobStatePending,
  selectSearchedJobs,
  selectSearchedParams,
} from '../../../store/selectors/job.selector';
import { MenuComponent } from '../user-management/components/menu/menu.component';
import { MenuActionComponent } from '../user-management/components/menu-action/menu-action.component';
import { SearchComponent } from './components/search/search.component';
import { CreateJobModalComponent } from './modals/create-job/create-job.component';

export const JOBS_AND_TESTS_PAGE_URL = 'jobs-and-tests';

@Component({
  standalone: true,
  templateUrl: './jobs-and-tests.page.html',
  imports: [
    LoadingComponent,
    CreateJobModalComponent,
    ButtonComponent,
    SegmentsComponent,
    SearchComponent,
    CommonModule,
    MenuComponent,
    MenuActionComponent,
  ],
})
export class JobsAndTestsOverviewPage implements OnInit {
  public jobs$ = this.store.select(selectSearchedJobs);
  public isPending$ = this.store.select(selectJobStatePending);

  public jobMenuOpen = '';
  public createJobModalOpen = false;

  public constructor(
    private readonly router: Router,
    private readonly store: Store,
  ) {}

  public async ngOnInit(): Promise<void> {
    const searchParams = await firstValueFrom(
      this.store.pipe(select(selectSearchedParams)),
    );

    if (!Object.keys(searchParams).length) {
      this.search({});
    }
  }

  public search(params: JobSearchParams): void {
    this.store.dispatch(JobActions.search({ params }));
  }

  public async viewJob(id: string): Promise<void> {
    await this.router.navigateByUrl(
      `${JOBS_AND_TESTS_PAGE_URL}/job/${encodeURIComponent(id)}`,
    );
  }

  public async createJobModalClosed(): Promise<void> {
    const searchParams = await firstValueFrom(
      this.store.pipe(select(selectSearchedParams)),
    );

    this.createJobModalOpen = false;
    this.search(searchParams);
  }
}
